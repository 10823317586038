/**
 * A simple throttle function.
 * Calls `fn` immediately, then ignores subsequent calls until
 * `wait` milliseconds have passed since the last call.
 */
export function throttle<CallbackArgs extends unknown[]>(fn: (...args: CallbackArgs) => void, wait: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let lastCall = 0;

  function throttled(this: unknown, ...args: CallbackArgs) {
    const now = Date.now();
    const remaining = wait - (now - lastCall);

    if (remaining <= 0 || remaining > wait) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      lastCall = now;
      fn.apply(this, args);
    } else if (!timeoutId) {
      timeoutId = setTimeout(() => {
        lastCall = Date.now();
        timeoutId = null;
        fn.apply(this, args);
      }, remaining);
    }
  }

  throttled.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return throttled;
}
