<template>
  <section data-fs="plpFacets" data-test-id="plpProductFilters">
    <CategoryDetails v-if="!isFilterTray" />

    <Accordion v-if="totalFoundItems > 0">
      <AccordionItem
        v-if="cachedCategoriesEnriched && !route.path.includes('search')"
        :id="`${isFilterTray ? 'filter-tray-' : ''}categoryFilter`"
        label="Categories"
        :is-filter="true"
        :border-top="false"
        open-multiple
        initially-expanded
      >
        <Stack tag="ul" gap="2xs" direction="col" class="pb-sm">
          <li v-for="category in cachedCategoriesEnriched" :key="category.id" class="w-full">
            <NuxtLink :to="category.url" class="flex items-center justify-start gap-2xs w-full">
              <Icon name="nav-left" :size="12" :class="category.parent && !category.active ? 'visible' : 'invisible'" />
              <Text tag="span" size="md" :weight="category.active ? 'bold' : 'semi'">
                {{ category.name }}
                <span v-if="!category.parent || category.active">({{ category.count }})</span>
              </Text>
            </NuxtLink>
          </li>
        </Stack>
      </AccordionItem>

      <AccordionItem
        v-if="selectedBranch"
        :id="`${isFilterTray ? 'filter-tray-' : ''}priceFilter`"
        label="Price"
        :is-filter="true"
        :border-top="selectedFacets === 0 && (cachedCategoriesEnriched?.length ?? 0) > 0"
        open-multiple
      >
        <CategoryPriceFilter @update:price="setRangeButton" :is-filter-tray="isFilterTray" />
      </AccordionItem>

      <AccordionItem
        v-for="(commonFacet, i) in cachedCommonFacets"
        :id="`${isFilterTray ? 'filter-tray-' : ''}${commonFacet.name}-${i}`"
        :label="commonFacet.name && $ft(`${commonFacet.name}`, commonFacet.name)"
        :key="`${commonFacet.name}-${i}`"
        :is-filter="true"
        border-top
        open-multiple
      >
        <template v-if="isFilterTray">
          <Stack tag="ul" direction="col" gap="xs" class="pb-sm">
            <li v-for="facet in commonFacet.values" :key="`${commonFacet.name}-${facet.id}`">
              <Checkbox
                :id="`${commonFacet.id}-${facet.id}-filter-tray`"
                class="px-[6px]"
                :model-value="filters[commonFacet.id] && isSelected(commonFacet.id, facet.id)"
                :value="filters[commonFacet.id] && isSelected(commonFacet.id, facet.id)"
                :name="`${commonFacet.id}-${facet.id}-filter-tray`"
                :label="`${facet.name} (${facet.count})`"
                @update:model-value="(value) => onFacetChange(value, commonFacet.id, facet.id)"
              />
            </li>
          </Stack>
        </template>
        <template v-else>
          <div
            class="virtual-scroll-container pb-sm"
            style="height: 300px; overflow-y: auto; contain: strict; -webkit-overflow-scrolling: touch"
            :data-test-id="`desktop-${commonFacet.name}`"
            @scroll="handleScroll($event, i)"
            ref="scrollContainers"
          >
            <div class="virtual-scroll-content" :style="{ height: viewportHeights[i] + 'px', position: 'relative' }">
              <Stack
                tag="ul"
                direction="col"
                gap="xs"
                class="gpu-accelerated"
                :style="{
                  transform: `translate3d(0, ${viewportScrollTops[i]}px, 0)`,
                  position: 'absolute',
                  width: '100%',
                  willChange: 'transform',
                }"
              >
                <Stack tag="li" v-for="facet in facetVisibleItems[i]" :key="`${facet.name}`">
                  <Checkbox
                    :id="`${commonFacet.id}-${facet.id}-filter`"
                    class="px-[6px]"
                    :model-value="filters[commonFacet.id] && isSelected(commonFacet.id, facet.id)"
                    :value="filters[commonFacet.id] && isSelected(commonFacet.id, facet.id)"
                    :name="`${commonFacet.id}-${facet.id}-filter`"
                    :label="`${facet.name} (${facet.count})`"
                    @update:model-value="(value) => onFacetChange(value, commonFacet.id, facet.id)"
                  />
                </Stack>
              </Stack>
            </div>
          </div>
        </template>
      </AccordionItem>
    </Accordion>
  </section>
</template>

<script lang="ts" setup>
import sortBy from "lodash.sortby";
import { throttle } from "~/helpers/throttle";
import { productFiltersTranslation } from "~/utils/productUnitTranslation";
import type { AppliedFilterProps, Facet } from "./categoryTypes";
import { removeTrailingSlash } from "mkm-avengers";

const props = defineProps<AppliedFilterProps>();
const { filters, change } = useFilters();
const route = useRoute();
const { selectedBranch } = useBranches();
const scrollContainers = ref<HTMLElement[]>([]);

const VIRTUAL_SCROLL_CONFIG = {
  itemHeight: 40,
  bufferSize: 5,
  throttleMs: 16,
  containerHeight: 300,
} as const;

const categoriesCache = ref(new Map());
const facetsCache = ref(new Map());
const facetLengthCache = ref(new Map());
const viewportScrollTops = ref<number[]>([]);
const viewportHeights = ref<number[]>([]);
const visibleItems = ref<Record<number, any[]>>({});

let scrollFrame: number | null = null;
const scrollHandlers = new Map();

const $ft = (key: any, fallback: string) =>
  productFiltersTranslation(key) || fallback.replace(/[/_-]/g, " ").replace(/^\w/, (m) => m.toUpperCase());

const isSelected = (facetId: string, value: string): boolean =>
  filters.value[facetId]?.some((selectedValue: string) => selectedValue === value) || false;

const categories = computed(() => (props.facets ?? []).find((facet) => facet.id === "category")?.values);

const cachedCategoriesEnriched = computed(() => {
  const cacheKey = JSON.stringify({
    categories: categories.value,
    filters: filters.value["category"],
    activeId: props.activeCategoryId,
  });
  if (categoriesCache.value.has(cacheKey)) {
    return categoriesCache.value.get(cacheKey);
  }
  const result = categories.value?.map((category) => {
    const selectedCategory = filters.value["category"]?.find((it: any) => it.id === category.id);
    return {
      ...category,
      id: category.id,
      parentId: category.parentId,
      active: category.id === props.activeCategoryId && filters.value["category"].length === 0,
      parent: category.parentId !== props.activeCategoryId,
      selected: selectedCategory ? { id: selectedCategory.id } : undefined,
      name: category.name,
      url: removeTrailingSlash(category.url),
    };
  });
  categoriesCache.value.set(cacheKey, result);
  if (categoriesCache.value.size > 20) {
    const firstKey = categoriesCache.value.keys().next().value;
    categoriesCache.value.delete(firstKey);
  }
  return result;
});

const cachedCommonFacets = computed(() => {
  const cacheKey = JSON.stringify(props.facets);
  if (facetsCache.value.has(cacheKey)) {
    return facetsCache.value.get(cacheKey);
  }
  const result = props.facets
    .filter((facet) => facet.id !== "category")
    .map((facet) =>
      facet.isRangeFilter
        ? facet
        : {
            ...facet,
            values: sortBy(facet.values, "name"),
          },
    );
  facetsCache.value.set(cacheKey, result);
  if (facetsCache.value.size > 20) {
    const firstKey = facetsCache.value.keys().next().value;
    facetsCache.value.delete(firstKey);
  }
  return result;
});

watch(
  () => props.facets,
  () => {
    categoriesCache.value.clear();
    facetsCache.value.clear();
    facetLengthCache.value.clear();
  },
  { deep: true },
);

const getFacetLength = (facetIndex: number) => {
  if (!facetLengthCache.value.has(facetIndex)) {
    const length = cachedCommonFacets.value[facetIndex]?.values?.length || 0;
    facetLengthCache.value.set(facetIndex, length);
  }
  return facetLengthCache.value.get(facetIndex);
};

const { itemHeight, bufferSize, throttleMs, containerHeight } = VIRTUAL_SCROLL_CONFIG;

const calculateViewportIndices = (scrollPos: number, viewHeight: number, totalItems: number) => {
  const startIndex = Math.floor(scrollPos / itemHeight);
  const visibleCount = Math.ceil(viewHeight / itemHeight);
  const endIndex = Math.min(startIndex + visibleCount + bufferSize, totalItems);
  const firstIndex = Math.max(0, startIndex - bufferSize);
  return {
    startIndex,
    endIndex,
    firstIndex,
    scrollTop: firstIndex * itemHeight,
    totalHeight: totalItems * itemHeight,
  };
};

const updateVisibleItems = (facetIndex: number, items: any[]) => {
  visibleItems.value = {
    ...visibleItems.value,
    [facetIndex]: items,
  };
};

const updateViewportScrollTop = (facetIndex: number, scrollTop: number) => {
  const updatedScrollTops = [...viewportScrollTops.value];
  updatedScrollTops[facetIndex] = scrollTop;
  viewportScrollTops.value = updatedScrollTops;
};

const updateViewportHeight = (facetIndex: number, height: number) => {
  const updatedHeights = [...viewportHeights.value];
  updatedHeights[facetIndex] = height;
  viewportHeights.value = updatedHeights;
};

watch(
  cachedCommonFacets,
  (newFacets: Facet[]) => {
    nextTick(() => {
      newFacets.forEach((facet: Facet, index: number) => {
        facetLengthCache.value.delete(index);
        const scrollContainer = scrollContainers.value[index];
        const currentContainerHeight = scrollContainer ? scrollContainer.clientHeight : containerHeight;
        const totalItems = getFacetLength(index);
        const currentScrollTop = viewportScrollTops.value[index] || 0;
        const viewport = calculateViewportIndices(currentScrollTop, currentContainerHeight, totalItems);
        updateViewportHeight(index, viewport.totalHeight);
        updateViewportScrollTop(index, viewport.scrollTop);
        if (facet.values) {
          const visibleFacets = facet.values.slice(viewport.firstIndex, viewport.endIndex) || [];
          updateVisibleItems(index, visibleFacets);
        }
      });
    });
  },
  { immediate: true },
);

const handleScroll = (event: Event, facetIndex: number) => {
  if (!scrollHandlers.has(facetIndex)) {
    scrollHandlers.set(
      facetIndex,
      throttle((e: Event) => {
        const target = e.target as HTMLElement;
        const { scrollTop, clientHeight } = target;
        const totalItems = getFacetLength(facetIndex);
        if (scrollFrame) {
          cancelAnimationFrame(scrollFrame);
        }
        scrollFrame = requestAnimationFrame(() => {
          const viewport = calculateViewportIndices(scrollTop, clientHeight, totalItems);
          updateViewportScrollTop(facetIndex, viewport.scrollTop);
          if (cachedCommonFacets.value[facetIndex]?.values) {
            const visibleFacets =
              cachedCommonFacets.value[facetIndex].values.slice(viewport.firstIndex, viewport.endIndex) || [];
            updateVisibleItems(facetIndex, visibleFacets);
          }
        });
      }, throttleMs),
    );
  }
  scrollHandlers.get(facetIndex)(event);
};

onBeforeUnmount(() => {
  if (scrollFrame) {
    cancelAnimationFrame(scrollFrame);
    scrollFrame = null;
  }
  scrollHandlers.forEach((handler) => {
    if (typeof handler.cancel === "function") {
      handler.cancel();
    }
  });
  scrollHandlers.clear();
  if (categoriesCache.value.size > 5) {
    const keysToRemove = Array.from(categoriesCache.value.keys()).slice(5);
    keysToRemove.forEach((key) => categoriesCache.value.delete(key));
  }
  if (facetsCache.value.size > 5) {
    const keysToRemove = Array.from(facetsCache.value.keys()).slice(5);
    keysToRemove.forEach((key) => facetsCache.value.delete(key));
  }
  facetLengthCache.value.clear();
});

const buildPriceRangeFacets = ({ from, to }: { from: string; to: string }) => [
  ...(from ? [{ id: "price", valueId: "from", value: from }] : []),
  ...(to ? [{ id: "price", valueId: "to", value: to }] : []),
];

const onFacetChange = async (value: boolean, facetId: string, valueId: string) => {
  const scrollState = [...viewportScrollTops.value];
  await change(value, { id: facetId, value: valueId });
  viewportScrollTops.value = scrollState;
};

const setRangeButton = async (params: { from: string; to: string }) => {
  const facets = buildPriceRangeFacets(params);
  const scrollState = [...viewportScrollTops.value];
  try {
    if (params.from) {
      facets.push({ id: "price", valueId: "from", value: params.from });
    }
    if (params.to) {
      facets.push({ id: "price", valueId: "to", value: params.to });
    }
    // @ts-ignore
    change(true, ...facets);
    viewportScrollTops.value = scrollState;
  } catch (error) {
    console.error("Error updating price range:", error);
  }
};

const selectedFacets = computed(() => {
  const excludedFilters = new Set(["category", "price"]);
  return Object.entries(filters.value)
    .filter(([key]) => !excludedFilters.has(key))
    .some(([, values]) => Array.isArray(values) && values.length > 0)
    ? 1
    : 0;
});

const facetVisibleItems = computed(() => {
  const result: Record<number, any[]> = {};
  Object.keys(visibleItems.value).forEach((index) => {
    const numIndex = Number(index);
    result[numIndex] = visibleItems.value[numIndex] || [];
  });
  return result;
});
</script>

<style>
.gpu-accelerated {
  transform-style: preserve-3d;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.virtual-scroll-container {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  contain: layout style paint;
}
.virtual-scroll-content {
  transform: translateZ(0);
}
</style>
